


$('.counter').counterUp({
    delay: 10,
    time: 1000
});
  




var swiper = new Swiper("#reviews", {
    slidesPerView: 1,
    spaceBetween: 24,
    loop: true,
    speed: 2000,
    swipeToSlide: true,
    autoplay: true,
    autoplay: {
        delay: 1000,
        disableOnInteraction: false,
        stopOnLast: false,
    },
    navigation: {
      nextEl: ".swiper-button-next1",
      prevEl: ".swiper-button-prev1"
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
    breakpoints: {
        0: {
            slidesPerView: 1,
        },
        575: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 1,
        },
        992: {
            slidesPerView: 1,
        },
    },
});